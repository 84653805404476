<template>
	<section class="container">
		<div class="row justify-content-center">
			<div class="col-12 mt-3">
				<h3>
					<b>Slet medarbejderens aftale</b>
				</h3>
			</div>
			<div class="col-auto">
				<BaseActionButton
					class="btn-delete"
					type="button"
					v-on:on-click="onSubmit"
					:disabled="!employeePolicyExist"
					v-if="$can('delete', 'EmployeePolicy')"
				>
					Slet
				</BaseActionButton>
			</div>
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row justify-content-center" v-if="!isActive && employeePolicyExist">
			<div class="col-12 mt-3">
				<h3>
					<b>Aktivere medarbejderens aftale</b>
				</h3>
			</div>
			<div class="col-auto">
				<BaseActionButton class="btn-update" type="button" v-on:on-click="activateEmployeePolicy" v-if="$can('delete', 'EmployeePolicy')">
					Aktivere medarbejderens aftale
				</BaseActionButton>
			</div>
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row justify-content-center" >
			<div class="col-12 mt-3">
				<h3>
					<b>Fjern medarbejderen fra virksomheden</b>
				</h3>
				<i>
					Her kan du slette medarbejderen fra virksomheden, så længe der ikke eksistere en aftale mellem virksomheden/medarbejderen. Hvis der
					eksisterer en aftale, så skal du først slette den</i
				>
			</div>
			<div class="col-auto">
				<BaseActionButton
					class="btn-delete"
					type="button"
					v-on:on-click="removeEmployee"
					:disabled="employeePolicyExist"
					v-if="$can('delete', 'EmployeePolicy')"
				>
					Fjern medarbejderen
				</BaseActionButton>
			</div>
			<div class="col-12">
				<hr />
			</div>
		</div>
		<template v-if="isActive">
			<div class="row justify-content-center">
				<div class="col-12 mt-3">
					<h3>
						<b>Fjern medarbejderen fra virksomheden, og flyt medarbejderen til fratrædelse-gruppen</b>
					</h3>
					<i>
						Her kan du slette medarbejderen fra virksomheden, og i processen bliver medarbejderens aftale automatisk sat til at være inaktivt.
						Medarbejderen bliver automatisk flyttet til fratrædelse-virksomhed. Medarbejderens aftale skal have en fratrædelse-dato sat til, og for
						at kunne flytte medarbejderen til fratrædelse-virksomhed skal fratrædelsen-datoen være udløbet.</i
					>
				</div>
				<div class="col-auto">
					<BaseActionButton
						class="btn-delete"
						type="button"
						v-on:on-click="resignEmployeeAndMove"
						v-if="$can('delete', 'EmployeePolicy') && isActive"
					>
						Fjern medarbejderen og flyt
					</BaseActionButton>
				</div>
				<div class="col-12">
					<hr />
				</div>
			</div>
		</template>
	</section>
</template>

<script>
import Swal from 'sweetalert2'

export default {
	name: 'EmployeeSettings',
	components: {},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			var employeeId = this.employeeId
			var companyId = this.companyId
			var policyId = this.employeePolicyId
			Swal.fire({
				title: 'Slette medarbejderens aftale?',
				text: 'Vil du slette medarbejderens aftale?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja slet!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						var result = await this.$store.dispatch('employeeVuex/deleteEmployeePolicy', { companyId, employeeId, policyId })
						return result
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire('Slettet!', 'Medarbejderens aftale er blevet slettet!', 'success')
					this.toast('Success', 'Medarbejderens aftale er blevet slettet', true)
					this.$router.push({
						name: 'CompanyEmployeeList',
						params: { companyId },
					})
					this.clicked = false
				}
			})
		},
		async removeEmployee() {
			var employeeId = this.employeeId
			var companyId = this.companyId
			Swal.fire({
				title: 'Fjerne medarbejderen?',
				text: 'Vil du fjerne medarbejderen fra virksomheden?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja fjern!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						var result = await this.$store.dispatch('companyVuex/removeEmployee', { companyId, employeeId })
						return result
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire('Fjernet!', 'Medarbejderen er blevet fjernet!', 'success')
					this.toast('Success', 'Medarbejderen er blevet fjernet!', true)
					this.$router.push({
						name: 'CompanyEmployeeList',
						params: { companyId },
					})
					this.clicked = false
				}
			})
		},
		async resignEmployeeAndMove() {
			var employeeId = this.employeeId
			var companyId = this.companyId
			Swal.fire({
				title: 'Fjern medarbejderen fra virksomheden?',
				text: 'Vil du fjerne medarbejderen fra denne virksomhed og flytte medarbejden til fratrædelse-gruppen?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja fjern!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						var result = await this.$store.dispatch('employeeVuex/resignEmployeeAndMove', { companyId, employeeId })
						return result
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire(
						'Slettet!',
						'Medarbejderens aftale er blevet slettet, og medarbejderen er blevet fjernet fra virksomheden og flyttet til fratrædelsegruppen!',
						'success'
					)
					this.toast('Success', 'Medarbejderen er blevet fjernet og flyttet!', true)
					this.$router.push({
						name: 'CompanyEmployeeList',
						params: { companyId },
					})
					this.clicked = false
				}
			})
		},
		async activateEmployeePolicy() {
			var employeeId = this.employeeId
			var companyId = this.companyId
			var id = this.employeePolicyId
			var data = this.employeePolicy
			data.isActive = true
			Swal.fire({
				title: 'Vil du aktivere medarbejderens aftale?',
				text: 'Vil du genansætte medarbejderen til denne virksomhed og medarbejderens aftale hermed bliver aktivt igen?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja aktivere!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						var result = await this.$store.dispatch('employeeVuex/updateEmployeePolicy', { id, companyId, employeeId, data })
						// Add employee again to the company
						await this.$store.dispatch('companyVuex/addEmployee', { companyId, employeeId })
						return result
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire('Aktiveret!', 'Medarbejderens aftale er blevet aktivt igen', 'success')
					this.toast('Success', 'Medarbejderens aftale er nu aktivt!', true)
					this.$router.push({
						name: 'CompanyEmployeeList',
						params: { companyId },
					})
					this.clicked = false
				}
			})
		},
	},
	computed: {
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		employeePolicyId() {
			return this.$store.getters['employeeVuex/employeePolicy']?.id
		},
		isActive() {
			return this.$store.getters['employeeVuex/employeePolicy']?.isActive
		},
		employeePolicyExist() {
			return this.$store.getters['employeeVuex/employeePolicy'] !== null
		},
		company() {
			return this.$store.getters['employeeVuex/company']
		},

		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
	},
}
</script>

<style></style>
